import React, { useState, useRef, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';

const emojis = ['🎁', '🎉', '🎊'];
const numberOfEmojis = 100;

const Charbirthday = () => {
  const [presentClicked, setPresentClicked] = useState(false);
  const [showEmojis, setShowEmojis] = useState(false);
  const [animationCycle, setAnimationCycle] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const controls = useAnimation();
  const presentRef = useRef(null);
  const birthdayMessageRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  },);

  const baseEmojiSize = 2; // Base font size in rem for a certain screen width (you might need to adjust this)
  const screenWidthThreshold = 768; // Example screen width to base the size on

  const dynamicEmojiSize = `${Math.max(1, baseEmojiSize * (screenWidth / screenWidthThreshold))}rem`;

  const presentVariants = {
    initial: { scale: 4 / 3 },
    hover: {
      rotate: [0, 10, -10, 10, 0],
      scale: 5 / 3,
      transition: { duration: 0.3, repeat: Infinity, repeatType: 'reverse' },
    },
    clicked: {
      scale: 0,
      opacity: 0,
      transition: { duration: 0.5 },
    },
    explode: {
      scale: [1, 1.5, 0],
      opacity: [1, 0.8, 0],
      transition: { duration: 0.3 },
    },
  };

  const getBirthdayTextPosition = () => {
    if (birthdayMessageRef.current) {
      const rect = birthdayMessageRef.current.getBoundingClientRect();
      return {
        x: rect.left + rect.width / 2,
        y: rect.top + rect.height / 2,
      };
    }
    return { x: window.innerWidth / 2, y: window.innerHeight / 2 };
  };

  const emojiVariants = {
    initial: (centerPosition) => ({
      x: centerPosition.x,
      y: centerPosition.y,
      opacity: 1,
      scale: 0.8,
      rotate: 0,
    }),
    explode: (index) => {
      const angle = (index / numberOfEmojis) * 2 * Math.PI;
      const radius = Math.random() * Math.min(window.innerWidth, window.innerHeight) * 0.6;
      return {
        x: radius * Math.cos(angle),
        y: radius * Math.sin(angle),
        opacity: 1,
        scale: 1 + Math.random() * 0.8,
        rotate: (Math.random() - 0.5) * 720,
        transition: {
          duration: 1 + Math.random() * 0.7,
          ease: 'easeInOut',
        },
      };
    },
    fade: {
      opacity: 0,
      scale: 0.5,
      transition: { duration: 1, ease: 'easeIn' },
    },
  };

  useEffect(() => {
    if (showEmojis) {
      const intervalId = setInterval(() => {
        setAnimationCycle((prevCycle) => prevCycle + 1);
      }, 1800);

      return () => clearInterval(intervalId);
    }
  }, [showEmojis]);

  const handlePresentClick = async () => {
    await controls.start('explode');
    setPresentClicked(true);
    setShowEmojis(true);
  };

  return (
    <div className={`birthday-page ${presentClicked ? 'celebration' : ''}`} style={{ position: 'relative', overflow: 'hidden' }}>
      {!presentClicked ? (
        <motion.div
          ref={presentRef}
          className="present-container"
          initial="initial"
          whileHover="hover"
          animate={controls}
          variants={presentVariants}
          onClick={handlePresentClick}
          style={{
            cursor: 'pointer',
            fontSize: '6.67rem',
          }}
        >
          🎁
        </motion.div>
      ) : (
        <div className="birthday-message" ref={birthdayMessageRef} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', minHeight: '100vh' }}>
          <h1>Happy Birthday Charlotte!</h1>
        </div>
      )}
      {presentClicked && showEmojis && Array.from({ length: numberOfEmojis }).map((_, index) => (
        <motion.div
          key={`${index}-${animationCycle}`}
          style={{
            position: 'absolute',
            fontSize: dynamicEmojiSize, // Use the dynamic font size here
            pointerEvents: 'none',
            x: 0,
            y: 0,
          }}
          variants={{
            initial: emojiVariants.initial(getBirthdayTextPosition()),
            explode: emojiVariants.explode(index),
            fade: emojiVariants.fade,
          }}
          initial="initial"
          animate={animationCycle % 2 === 0 ? 'explode' : 'fade'}
          transition={{ delay: animationCycle % 2 === 1 ? 0.8 : 0 }}
        >
          {emojis[index % emojis.length]}
        </motion.div>
      ))}
    </div>
  );
};

export default Charbirthday;