import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Boids from './pages/Boids';
import TimelinePage from './pages/TimelinePage';
import Valentine from './pages/Valentine';
import Charbirthday from './pages/Charbirthday';

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/boids" element={<Boids />} />
          <Route path="/timeline" element={<TimelinePage />} />
          <Route path="/valentine" element={<Valentine />} />
          <Route path="/charbirthday" element={<Charbirthday />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;